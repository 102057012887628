var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('v-row', {
    staticClass: "pt-8 pl-4 mb-3",
    attrs: {
      "align": "center"
    }
  }, [_vm.functions.hasSufficientRights(_vm.constants.Rights.BASIC_READ) ? _c('v-btn', {
    staticClass: "mr-5 rounded-lg white",
    attrs: {
      "text": "",
      "elevation": "0",
      "color": "primary"
    },
    on: {
      "click": _vm.listeners.onToggleFilterDropdown
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiFilter) + " ")]), _vm._v(" " + _vm._s(_vm.$t('misc.filter')) + " "), _c('v-icon', {
    attrs: {
      "right": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.state.isFilterDropdownOpen ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown) + " ")])], 1) : _vm._e()], 1), _c('v-expand-transition', {
    attrs: {
      "mode": "in-out"
    }
  }, [_vm.state.isFilterDropdownOpen ? _c('ComparisonFilterBar', {
    staticClass: "ml-1 d-flex align-center",
    attrs: {
      "fields": _vm.constants.FILTER_FIELDS
    },
    scopedSlots: _vm._u([{
      key: "headersFilter",
      fn: function fn() {
        return [_c('v-select', {
          attrs: {
            "items": _vm.state.tableHeaders,
            "item-text": "text",
            "item-value": "value",
            "label": _vm.$t('planning.workforcePlanning.filters.headers'),
            "multiple": "",
            "dense": "",
            "outlined": ""
          },
          scopedSlots: _vm._u([{
            key: "selection",
            fn: function fn(_ref) {
              var item = _ref.item,
                  index = _ref.index;
              return [index === 0 ? _c('span', [_vm._v(" " + _vm._s(item.text))]) : _vm._e(), index === 1 ? _c('span', {
                staticClass: "grey--text text-caption"
              }, [_vm._v(" (+" + _vm._s(_vm.state.selectedHeaders.length - 1) + " others) ")]) : _vm._e()];
            }
          }], null, false, 2141828027),
          model: {
            value: _vm.state.selectedHeaders,
            callback: function callback($$v) {
              _vm.$set(_vm.state, "selectedHeaders", $$v);
            },
            expression: "state.selectedHeaders"
          }
        })];
      },
      proxy: true
    }], null, false, 873847602)
  }) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "justify-center d-flex"
  }, [_c('ComparisonTable', {
    attrs: {
      "filter-fields": _vm.constants.FILTER_FIELDS,
      "positions-to-compare": _vm.state.selectedDataRight,
      "hovered-position-id": _vm.state.hoveredPositionId,
      "selected-headers": _vm.state.selectedHeaders
    },
    on: {
      "selectedData": function selectedData($event) {
        return _vm.listeners.onDataSelectLeft($event);
      },
      "row-hover": function rowHover($event) {
        return _vm.listeners.onRowHover($event);
      },
      "row-leave": _vm.listeners.onRowLeave,
      "loaded-headers": function loadedHeaders($event) {
        return _vm.listeners.onLoadedHeaders($event);
      }
    }
  }), _c('v-divider', {
    staticClass: "mx-2 my-6",
    attrs: {
      "vertical": ""
    }
  }), _c('ComparisonTable', {
    attrs: {
      "filter-fields": _vm.constants.FILTER_FIELDS,
      "positions-to-compare": _vm.state.selectedDataLeft,
      "hovered-position-id": _vm.state.hoveredPositionId,
      "selected-headers": _vm.state.selectedHeaders
    },
    on: {
      "selectedData": function selectedData($event) {
        return _vm.listeners.onDataSelectRight($event);
      },
      "row-hover": function rowHover($event) {
        return _vm.listeners.onRowHover($event);
      },
      "row-leave": _vm.listeners.onRowLeave
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }