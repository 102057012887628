
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch,
  PropType,
  ComputedRef,
  onMounted,
} from '@vue/composition-api'
import { isEqual } from 'lodash-es'

import CommonAutocomplete from '@/components/common/CommonAutocomplete.vue'
import DotIndicator from '@/components/common/CommonDotIndicator.vue'
import ComparisonBudgetBox from '@/views/planning/views/workforcePlanningComparison/components/ComparisonBudgetBox.vue'

import { useFilter } from '@/composables/useFilter'
import { hasSufficientRights } from '@/utils/hasSufficientRights'
import { dateDashNotationToDot } from '@/utils/convertDate'
import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { convertToEuro } from '@/utils/convertCurrency'
import { setSort } from '@/utils/manipulateTableSort'
import { convertPositionStatus } from '@/utils/convertPositionStatus'
import { convertSourcingType } from '@/utils/convertSourcingType'

import { useGetPositionScenariosBasic } from '@/api/positionsScenario'
import { useGetPositions, useGetPositionsComparisonKpi } from '@/api/position'

import { Rights } from '@/api/types/right'
import { BasicEntity } from '@/api/types/misc'
import { FilterField, OptionalFiltersParam } from '@/composables/types/useFilter'
import { PositionOutput } from '@/api/types/position'
import { POSITION_PROPERTIES, TableHeader } from '@/views/planning/views/workforcePlanningComparison/types'

export default defineComponent({
  name: 'ComparisonTable',
  components: {
    CommonAutocomplete,
    DotIndicator,
    ComparisonBudgetBox,
  },
  props: {
    filterFields: {
      type: Array as PropType<FilterField[]>,
      default: null,
    },
    positionsToCompare: {
      type: Array as PropType<PositionOutput[]>,
      default: null,
    },
    selectedHeaders: {
      type: Array as PropType<string[]>,
      default: null,
    },
    hoveredPositionId: {
      type: String,
      default: null,
    },
  },
  setup: (props, { root, emit }) => {
    const selectedData = ref<number | null>(-1)

    const TABLE_HEADERS: ComputedRef<TableHeader[]> = computed(() => [
      {
        text: root.$t('planning.workforcePlanning.table.col.title.id'),
        value: selectedData.value === -1 ? 'id' : 'scenarioOriginalPosition',
        sortable: true,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.plannedPerson'),
        value: 'plannedPerson',
        sortable: false,
        width: 150,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.organizationalUnit'),
        value: 'organizationalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.professionalUnit'),
        value: 'professionalUnit',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.profile'),
        value: 'profile',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.scope'),
        value: 'scope',
        sortable: false,
      },
      hasSufficientRights(Rights.FINANCIAL_READ)
        ? {
            text: root.$t('planning.workforcePlanning.table.col.title.hourlyRate'),
            value: 'hourlyRate',
            sortable: false,
          }
        : ({} as TableHeader),
      {
        text: root.$t('planning.workforcePlanning.table.col.title.start'),
        value: 'start',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.end'),
        value: 'end',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.sourcingType'),
        value: 'sourcingType',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.unlimited'),
        value: 'limited',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.approved'),
        value: 'approvals',
        sortable: false,
      },
      {
        text: root.$t('planning.workforcePlanning.table.col.title.positionStatus'),
        value: 'positionStatus',
        sortable: false,
      },
    ])

    const filteredTableHeaders = computed<TableHeader[]>(() => {
      const baseTableHeaders = [TABLE_HEADERS.value[0]]

      TABLE_HEADERS.value.filter((header) => {
        if (props.selectedHeaders.includes(header.value)) {
          baseTableHeaders.push(header)
        }
      })

      return baseTableHeaders
    })

    onMounted(() =>
      emit(
        'loaded-headers',
        TABLE_HEADERS.value.filter(
          (header) =>
            header.value !== POSITION_PROPERTIES.ID && header.value !== POSITION_PROPERTIES.SCENARIO_ORIGINAL_POSITION
        )
      )
    )

    const {
      exec: getPositions,
      data: positions,
      isLoading: isLoadingPositions,
      paginationResponse: paginationResponsePositions,
    } = useGetPositions()

    const {
      exec: getScenarioPositions,
      data: scenarioPositions,
      isLoading: isLoadingScenarioPositions,
      paginationResponse: paginationResponseScenarioPositions,
    } = useGetPositions()

    const {
      exec: getComparisonKpi,
      data: comparisonKpi,
      isLoading: isLoadingComparisonKpi,
    } = useGetPositionsComparisonKpi()

    const {
      exec: getPositionScenariosBasic,
      data: positionScenariosBasic,
      isLoading: isLoadingPositionScenariosBasic,
    } = useGetPositionScenariosBasic()
    getPositionScenariosBasic()

    //Plan Stand = current positions
    const dataSets = computed<BasicEntity[]>(() => {
      return [{ id: -1, name: 'Plan-Stand' }, ...(positionScenariosBasic.value ?? [])]
    })

    // set selectedData initial to positions
    watch(
      () => selectedData.value,
      () => {
        if (selectedData.value) {
          if (selectedData.value === -1) {
            setSort(vuetifyTableOptions, { by: 'id', desc: false })
          } else {
            setSort(vuetifyTableOptions, { by: 'scenarioOriginalPosition', desc: false })
          }
          debouncedCb.value()
        }
      }
    )

    //use data based on selection
    function getSelectedTableData(selectedData: number | null): PositionOutput[] {
      if (selectedData === -1) {
        return positions.value
      } else if (selectedData) {
        return scenarioPositions.value
      } else {
        return []
      }
    }

    const tableData = computed<PositionOutput[]>(() => {
      return getSelectedTableData(selectedData.value)
    })

    watch(
      () => tableData.value,
      () => {
        if (tableData.value) {
          emit('selectedData', getSelectedTableData(selectedData.value))
        }
      }
    )

    const { debouncedCb, vuetifyTableOptions, paginationParams, filterFieldsObject, isInit } = useFilter(
      props.filterFields as FilterField[],
      init
    )

    watch(
      filterFieldsObject,
      (newVal, oldVal) => {
        isInit.value = false

        if (!isEqual(newVal, oldVal)) {
          debouncedCb.value()
          vuetifyTableOptions.value.page = 1
        }
      },
      { deep: true }
    )

    async function init(filterFieldsObject?: OptionalFiltersParam): Promise<void> {
      // get current positions
      if (selectedData.value === -1) {
        await getPositions({
          params: { ...paginationParams.value, ...filterFieldsObject },
        })

        await getComparisonKpi({ params: { ...filterFieldsObject } })
      } else {
        // or get selected scenario
        await getScenarioPositions({
          params: {
            ...paginationParams.value,
            ...filterFieldsObject,
            scenarioIsNull: false,
            scenarioIds: selectedData.value,
          },
        })

        await getComparisonKpi({
          params: {
            ...filterFieldsObject,
            scenarioIsNull: false,
            scenarioIds: selectedData.value,
          },
        })
      }
    }

    const isLoading = computed<boolean>(() => {
      return isLoadingPositions.value || isLoadingScenarioPositions.value
    })

    const totalPositions = computed<number>(() => {
      if (selectedData.value === -1) {
        return paginationResponsePositions.value.totalElements
      } else {
        return paginationResponseScenarioPositions.value.totalElements
      }
    })

    function propertyEqualsPositionToCompare(row: PositionOutput, property: string): boolean | undefined {
      const positionToCompare = props.positionsToCompare.find((position) => {
        return position.positionId === row.positionId
      })

      if (!positionToCompare) return

      return isEqual(row[property], positionToCompare[property])
    }

    function onRowHover(positionId: string): void {
      emit('row-hover', positionId)
    }

    function onRowLeave(): void {
      emit('row-leave')
    }

    function isTableHeaderVisible(property: string): boolean {
      return props.selectedHeaders.includes(property)
    }

    const isItemsPerPageDefault = ref<boolean>(true)

    function onUpdateItemsPerPage(itemsPerPage: number) {
      isItemsPerPageDefault.value = itemsPerPage === 10
    }

    return reactive({
      constants: {
        POSITION_PROPERTIES,
      },
      state: {
        isLoadingPositionScenariosBasic,
        dataSets,
        selectedData,

        tableData,
        isLoading,
        totalPositions,

        comparisonKpi,
        isLoadingComparisonKpi,

        vuetifyTableOptions,

        filterFieldsObject,

        filteredTableHeaders,

        isItemsPerPageDefault,
      },
      functions: {
        convertPositionStatus,
        convertSourcingType,
        convertDotIntoCommaInput,
        convertToEuro,
        dateDashNotationToDot,

        debouncedCb,

        propertyEqualsPositionToCompare,

        isTableHeaderVisible,
      },
      listeners: {
        onRowHover,
        onRowLeave,
        onUpdateItemsPerPage,
      },
    })
  },
})
