var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-1/2"
  }, [_c('v-card', {
    staticClass: "w-1/3 ml-3 rounded-lg pa-4",
    attrs: {
      "elevation": "0"
    }
  }, [_c('CommonAutocomplete', {
    staticClass: "rounded",
    attrs: {
      "items": _vm.state.dataSets,
      "item-text": "name",
      "item-value": "id",
      "loading": _vm.state.isLoadingPositionScenariosBasic,
      "label": _vm.$t('planning.workforcePlanning.comparison.title'),
      "hide-details": "",
      "clearable": "",
      "dense": "",
      "outlined": ""
    },
    model: {
      value: _vm.state.selectedData,
      callback: function callback($$v) {
        _vm.$set(_vm.state, "selectedData", $$v);
      },
      expression: "state.selectedData"
    }
  })], 1), _c('ComparisonBudgetBox', {
    attrs: {
      "filter": _vm.state.filterFieldsObject,
      "comparison-kpi": _vm.state.comparisonKpi,
      "is-loading-comparison-kpi": _vm.state.isLoadingComparisonKpi,
      "data-sets": _vm.state.dataSets,
      "selected-data-id": _vm.state.selectedData
    }
  }), _c('v-data-table', {
    staticClass: "mx-3 mt-3 rounded-lg scrollSync elevation-0 pa-3",
    attrs: {
      "fixed-header": "",
      "height": _vm.state.isItemsPerPageDefault ? '53vh' : '70vh',
      "footer-props": {
        'items-per-page-options': [10, 50, 100]
      },
      "headers": _vm.state.filteredTableHeaders,
      "items": _vm.state.tableData,
      "loading": _vm.state.isLoading,
      "server-items-length": _vm.state.totalPositions,
      "options": _vm.state.vuetifyTableOptions
    },
    on: {
      "update:options": [function ($event) {
        return _vm.$set(_vm.state, "vuetifyTableOptions", $event);
      }, function ($event) {
        return _vm.functions.debouncedCb();
      }],
      "update:items-per-page": function updateItemsPerPage($event) {
        return _vm.listeners.onUpdateItemsPerPage($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(_ref) {
        var _item$scenarioOrigina;

        var item = _ref.item;
        return [_c('tr', {
          key: item.positionId,
          class: _vm.hoveredPositionId === item.positionId && 'row__bg',
          on: {
            "mouseover": function mouseover($event) {
              return _vm.listeners.onRowHover(item.positionId);
            },
            "mouseleave": function mouseleave($event) {
              return _vm.listeners.onRowLeave();
            }
          }
        }, [_c('td', [_vm._v(" " + _vm._s(_vm.state.selectedData === -1 ? item.id : (_item$scenarioOrigina = item.scenarioOriginalPosition) === null || _item$scenarioOrigina === void 0 ? void 0 : _item$scenarioOrigina.id) + " ")]), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.PLANNED_PERSON) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.PLANNED_PERSON) && 'hover'
        }, [_vm._v(" " + _vm._s(item.plannedPerson) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.ORGANIZATIONAL_UNIT) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.ORGANIZATIONAL_UNIT) && 'hover'
        }, [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.organizationalUnit.name) + " ")])], 1)]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.PROFESSIONAL_UNIT) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.PROFESSIONAL_UNIT) && 'hover'
        }, [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.professionalUnit.name) + " ")])], 1)]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.PROFILE) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.PROFILE) && 'hover'
        }, [_c('span', [_c('v-chip', {
          attrs: {
            "small": ""
          }
        }, [_vm._v(" " + _vm._s(item.profile.name) + " ")])], 1)]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.SCOPE) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.SCOPE) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.convertDotIntoCommaInput(item.scope, 2)) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.HOURLY_RATE) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.HOURLY_RATE) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.convertToEuro(item.hourlyRate)) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.START) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.START) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.start)) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.END) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.END) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(item.end)) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.SOURCING_TYPE) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.SOURCING_TYPE) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.convertSourcingType(item.sourcingType)) + " ")]) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.LIMITED) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.LIMITED) && 'hover'
        }, [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.limited ? 'red' : 'green')
          }
        })], 1) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.APPROVALS) ? _c('td', [_c('DotIndicator', {
          attrs: {
            "color": "".concat(item.approvals.length ? 'green' : 'red')
          }
        })], 1) : _vm._e(), _vm.functions.isTableHeaderVisible(_vm.constants.POSITION_PROPERTIES.POSITION_STATUS) ? _c('td', {
          class: !_vm.functions.propertyEqualsPositionToCompare(item, _vm.constants.POSITION_PROPERTIES.POSITION_STATUS) && 'hover'
        }, [_vm._v(" " + _vm._s(_vm.functions.convertPositionStatus(item.positionStatus)) + " ")]) : _vm._e()])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }