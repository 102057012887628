import { useAxios } from '@/composables/useAxios'
import { useAxiosPaginated } from '@/composables/useAxiosPaginated'

import { PromiseType } from '@/utils/types/PromiseType'
import {
  PositionScenarioId,
  PositionScenarioInput,
  PositionScenarioOutput,
  UseCreatePositionScenario,
  UseDeletePositionScenario,
  UseGetPositionScenario,
  UseGetPositionScenarios,
  UseGetPositionScenariosBasic,
  UseUpdatePositionScenario,
} from './types/positionScenario'
import { BasicEntity } from './types/misc'

const VERSION = 'v1'
const RESOURCE = 'position-scenarios'

function useGetPositionScenarios(): UseGetPositionScenarios {
  const axios = useAxiosPaginated<PromiseType<ReturnType<UseGetPositionScenarios['exec']>>>({
    method: 'GET',
    url: `/${VERSION}/${RESOURCE}`,
    params: { size: 9999 },
  })
  return axios
}

function useGetPositionScenario(): UseGetPositionScenario {
  const axios = useAxios<PromiseType<ReturnType<UseGetPositionScenario['getPositionScenario']>>>({
    method: 'GET',
  })

  function getPositionScenario(id: PositionScenarioId): Promise<PositionScenarioOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    getPositionScenario,
  }
}

function useGetPositionScenariosBasic(): UseGetPositionScenariosBasic {
  const axios = useAxios<BasicEntity[]>({ method: 'GET', url: `/${VERSION}/${RESOURCE}/basic` })
  return axios
}

function useCreatePositionScenario(): UseCreatePositionScenario {
  const axios = useAxios<PromiseType<ReturnType<UseCreatePositionScenario['createPositionScenario']>>>({
    method: 'POST',
    url: `/${VERSION}/${RESOURCE}`,
  })

  function createPositionScenario(data: PositionScenarioInput): Promise<PositionScenarioOutput> {
    return axios.exec({ data })
  }

  return {
    ...axios,
    createPositionScenario,
  }
}

function useUpdatePositionScenario(): UseUpdatePositionScenario {
  const axios = useAxios<PromiseType<ReturnType<UseUpdatePositionScenario['updatePositionScenario']>>>({
    method: 'PUT',
  })

  function updatePositionScenario(
    id: PositionScenarioId,
    data: PositionScenarioOutput
  ): Promise<PositionScenarioOutput> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}`, data })
  }

  return {
    ...axios,
    updatePositionScenario,
  }
}

function useDeletePositionScenario(): UseDeletePositionScenario {
  const axios = useAxios<PromiseType<ReturnType<UseDeletePositionScenario['deletePositionScenario']>>>({
    method: 'DELETE',
  })

  function deletePositionScenario(id: PositionScenarioId): Promise<void> {
    return axios.exec({ url: `/${VERSION}/${RESOURCE}/${id}` })
  }

  return {
    ...axios,
    deletePositionScenario,
  }
}

export {
  useGetPositionScenarios,
  useGetPositionScenario,
  useGetPositionScenariosBasic,
  useCreatePositionScenario,
  useUpdatePositionScenario,
  useDeletePositionScenario,
}
