
import { defineComponent, reactive, PropType, watch, ref } from '@vue/composition-api'

import { convertDotIntoCommaInput } from '@/utils/convertInput'
import { convertToEuro } from '@/utils/convertCurrency'
import { dateDashNotationToDot } from '@/utils/convertDate'

import { PositionComparisonKpiOutput } from '@/api/types/position'
import { BasicEntity } from '@/api/types/misc'
import { OptionalFiltersParam } from '@/composables/types/useFilter'
import { round } from 'lodash-es'

export default defineComponent({
  name: 'ComparisonBudgetBox',
  props: {
    comparisonKpi: {
      type: Object as PropType<PositionComparisonKpiOutput>,
      default: null,
    },
    isLoadingComparisonKpi: {
      type: Boolean,
      default: false,
    },
    dataSets: {
      type: Array as PropType<BasicEntity[]>,
      default: () => [],
    },
    selectedDataId: {
      type: Number,
      default: null,
    },
    filter: {
      type: Object as PropType<OptionalFiltersParam>,
      default: null,
    },
  },
  setup: (props) => {
    function getComparisonKpiHeader(kpi: PositionComparisonKpiOutput): string | undefined {
      if (!kpi) return

      const selectedDataName = props.dataSets.find((data) => data.id === props.selectedDataId)?.name

      const start = hasRightFormat(props.filter.endFrom as string) ? String(props.filter.endFrom) : null

      const end = hasRightFormat(props.filter.startTo as string) ? String(props.filter.startTo) : null

      if (start && !end) {
        // Start is set without end
        return `Ab ${dateDashNotationToDot(start)} (${selectedDataName})`
      } else if (end && !start) {
        // End is set without start
        return `Bis ${dateDashNotationToDot(end)} (${selectedDataName})`
      } else if (!start && !end) {
        // Neither start nor end is set
        return `${selectedDataName}`
      } else {
        // Start and end are set
        return `
          ${dateDashNotationToDot(String(start))} - ${dateDashNotationToDot(String(end))} (${selectedDataName})`
      }
    }

    const isScenarioDisplayed = ref<boolean>(false)

    watch(
      () => props.selectedDataId,
      () => {
        if (props.selectedDataId === -1) {
          isScenarioDisplayed.value = false
        } else {
          isScenarioDisplayed.value = true
        }
      },
      { immediate: true }
    )

    function hasRightFormat(filter: string): boolean {
      const dateFormat = new RegExp(/^\d{4}-\d{2}-\d{2}$/) // YYYY-MM-DD

      if (!filter) return false

      if (!dateFormat.test(filter)) return false

      return true
    }

    function getTodaysDate() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
      const yyyy = today.getFullYear()

      return `${dd}.${mm}.${yyyy}`
    }

    function formatBudget(budget: number): string {
      if (budget > 100000) {
        return convertToEuro(round(budget, -3), false) ?? '0 €'
      } else {
        return convertToEuro(round(budget), false) ?? '0 €'
      }
    }

    return reactive({
      state: {
        isScenarioDisplayed,
      },
      functions: {
        convertDotIntoCommaInput,

        formatBudget,

        getComparisonKpiHeader,

        getTodaysDate,
      },
    })
  },
})
