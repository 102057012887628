
import { computed, defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { mdiChevronDown, mdiChevronUp, mdiFilter } from '@mdi/js'

import ComparisonTable from '@/views/planning/views/workforcePlanningComparison/components/ComparisonTable.vue'

import { hasSufficientRights } from '@/utils/hasSufficientRights'

import { useGetProfUnitsBasic } from '@/api/profUnit'
import { useGetProfilesBasic } from '@/api/profile'

import { ACTIVE_TENDERING, FILTER_FIELD_KEY, POSITION_STATUS, SOURCING_TYPE } from '../workforcePlanning/types'
import { Rights } from '@/api/types/right'
import { FilterDataTypes, FilterField } from '@/composables/types/useFilter'
import { PositionOutput } from '@/api/types/position'
import { useGetOrgUnitsBasic } from '@/api/orgUnit'
import { TableHeader } from './types'
import { useGetPositionsBasic } from '@/api/position'
import { PROFILE_TYPE } from '@/api/types/profile'
import { CONTRACTUAL_RELATIONSHIP } from '@/views/types'

export default defineComponent({
  name: 'Comparison',
  components: {
    ComparisonTable,
    ComparisonFilterBar: () =>
      import('@/views/planning/views/workforcePlanningComparison/components/ComparisonFilterBar.vue'),
  },
  setup: (_, { root }) => {
    const isFilterDropdownOpen = ref(false)

    function onToggleFilterDropdown() {
      if (!isFilterDropdownOpen.value) {
        isFilterDropdownOpen.value = true

        if (!profUnits.value?.length || !orgUnits.value?.length || !profiles.value?.length) {
          getProfUnitsBasic()
          getOrgUnitsBasic()
          getProfilesBasic({ params: { type: PROFILE_TYPE.ASSIGNMENT } })
          getPositionsBasic()
        }
      } else {
        isFilterDropdownOpen.value = false
      }
    }

    function getStartAndEndOfCurrentYear(): { startOfCurrentYear: string; endOfCurrentYear: string } {
      const today = new Date()
      const startOfYear = new Date(today.getFullYear(), 0, 1) // January 1st of the current year
      const endOfYear = new Date(today.getFullYear() + 1, 0, 0) // December 31st of the current year

      // Formatting the dates as strings in the format DD.MM.YYYY
      const formattedStart = `${('0' + startOfYear.getDate()).slice(-2)}.${('0' + (startOfYear.getMonth() + 1)).slice(
        -2
      )}.${startOfYear.getFullYear()}`
      const formattedEnd = `${('0' + endOfYear.getDate()).slice(-2)}.${('0' + (endOfYear.getMonth() + 1)).slice(
        -2
      )}.${endOfYear.getFullYear()}`

      return { startOfCurrentYear: formattedStart, endOfCurrentYear: formattedEnd }
    }

    const { startOfCurrentYear, endOfCurrentYear } = getStartAndEndOfCurrentYear()

    const { exec: getProfUnitsBasic, data: profUnits, isLoading: isLoadingProfUnits } = useGetProfUnitsBasic()

    const { exec: getOrgUnitsBasic, data: orgUnits, isLoading: isLoadingOrgUnits } = useGetOrgUnitsBasic()

    const { exec: getProfilesBasic, data: profiles, isLoading: isLoadingProfiles } = useGetProfilesBasic()

    const {
      exec: getPositionsBasic,
      data: positionsBasic,
      isLoading: isLoadingPositionsBasic,
    } = useGetPositionsBasic()

    const FILTER_FIELDS = ref<FilterField[]>([
      {
        key: FILTER_FIELD_KEY.PersonName,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.person'),
        items: [],
        dataTyp: FilterDataTypes.String,
      },
      {
        key: FILTER_FIELD_KEY.Ids,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.ids'),
        items: computed(() => positionsBasic.value ?? []),
        isLoading: computed(() => isLoadingPositionsBasic.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfessionalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.professionalUnit'),
        items: computed(() => profUnits.value ?? []),
        isLoading: computed(() => isLoadingProfUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.OrganizationalUnitIdsWithChildren,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.organizationalUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.ProfileIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.profile'),
        items: computed(() => profiles.value ?? []),
        isLoading: computed(() => isLoadingProfiles.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.BudgetSourceOrgUnitIds,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.budgetSourceOrgUnit'),
        items: computed(() => orgUnits.value ?? []),
        isLoading: computed(() => isLoadingOrgUnits.value),
        dataTyp: FilterDataTypes.ArrayNumber,
      },
      {
        key: FILTER_FIELD_KEY.StartTo,
        value: endOfCurrentYear,
        label: root.$t('planning.workforcePlanning.filters.startTo'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.EndFrom,
        value: startOfCurrentYear,
        label: root.$t('planning.workforcePlanning.filters.endFrom'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.ContractualRelationships,
        value: null,
        label: root.$t('contractData.contracts.filters.contractualRelationships'),
        items: computed(() => CONTRACTUAL_RELATIONSHIP),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.SourcingTypes,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.sourcingTypes'),
        items: computed(() => SOURCING_TYPE ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ScopeFrom,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeFrom'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.ScopeTo,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.scopeTo'),
        items: [],
        dataTyp: FilterDataTypes.Float,
      },
      {
        key: FILTER_FIELD_KEY.PositionStatuses,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.positionStatuses'),
        items: computed(() => POSITION_STATUS ?? []),
        dataTyp: FilterDataTypes.ArrayString,
      },
      {
        key: FILTER_FIELD_KEY.ActiveTendering,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.activeTendering'),
        items: computed(() => ACTIVE_TENDERING ?? []),
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.NotAssignedByDate,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notAssignedByDate'),
        items: [],
        dataTyp: FilterDataTypes.Date,
      },
      {
        key: FILTER_FIELD_KEY.Limited,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.limited'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
      {
        key: FILTER_FIELD_KEY.NotApproved,
        value: null,
        label: root.$t('planning.workforcePlanning.filters.notApproved'),
        items: [],
        dataTyp: FilterDataTypes.Boolean,
      },
    ])

    function scrollSync(): void {
      const selector = '.scrollSync'

      let active: Element | null = document.querySelector(selector) // Initialize with the first element

      let leftTable = document.querySelectorAll(selector)[0].firstChild // Get left table div
      let rightTable = document.querySelectorAll(selector)[1].firstChild // Get right table div

      // Toggle the event listener
      document.querySelectorAll(selector).forEach((element) => {
        // Register on which side the scroll will happen with mouseenter
        element.addEventListener('mouseenter', mouseenterHandler)
      })

      // Mouseenter event handler
      function mouseenterHandler(event) {
        active = event.target.firstChild

        // Sync inactive table with the same scroll
        if (active === leftTable) {
          synchronizeInactiveTableScroll(rightTable)
        } else if (active === rightTable) {
          synchronizeInactiveTableScroll(leftTable)
        }
      }

      // Sync active and inactive scroll
      function synchronizeInactiveTableScroll(targetTableSide) {
        const eventList = ['scroll', 'wheel']

        eventList.forEach((event) => {
          if (!active) return

          active.addEventListener(event, () => {
            if (!active) return

            targetTableSide.scrollTop = active.scrollTop // vertical scroll
            targetTableSide.scrollLeft = active.scrollLeft // horizontal scroll
          })
        })
      }
    }

    onMounted(() => scrollSync())

    const selectedDataLeft = ref<PositionOutput[]>([])
    const selectedDataRight = ref<PositionOutput[]>([])

    function onDataSelectLeft(positionArray: PositionOutput[]) {
      selectedDataLeft.value = positionArray
    }

    function onDataSelectRight(positionArray: PositionOutput[]) {
      selectedDataRight.value = positionArray
    }

    const hoveredPositionId = ref<string | null>(null)

    function onRowHover(positionId: string): void {
      hoveredPositionId.value = positionId
    }

    function onRowLeave(): void {
      hoveredPositionId.value = null
    }

    const tableHeaders = ref<TableHeader[]>([])

    const selectedHeaders = ref<string[]>([])

    function onLoadedHeaders(headers: TableHeader[]): void {
      tableHeaders.value = headers
    }

    watch(
      () => tableHeaders.value,
      () => {
        if (tableHeaders.value && tableHeaders.value.length) {
          selectedHeaders.value = tableHeaders.value.map((header) => header.value)
        }
      }
    )

    return reactive({
      icons: {
        mdiFilter,
        mdiChevronUp,
        mdiChevronDown,
      },
      constants: {
        Rights,

        FILTER_FIELDS,
      },
      state: {
        isFilterDropdownOpen,

        selectedDataLeft,
        selectedDataRight,

        hoveredPositionId,

        tableHeaders,
        selectedHeaders,
      },
      listeners: {
        onToggleFilterDropdown,
        onDataSelectLeft,
        onDataSelectRight,

        onRowHover,
        onRowLeave,
        onLoadedHeaders,
      },
      functions: {
        hasSufficientRights,
      },
    })
  },
})
