var render = function () {
  var _vm$comparisonKpi, _vm$comparisonKpi2, _vm$comparisonKpi3, _vm$comparisonKpi4, _vm$comparisonKpi5, _vm$comparisonKpi6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "rounded-lg elevation-0 align-self-center mx-3 mt-3 pa-3",
    attrs: {
      "height": "26vh",
      "loading": _vm.isLoadingComparisonKpi
    }
  }, [_c('v-card-title', {
    staticClass: "mb-3"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.functions.getComparisonKpiHeader(_vm.comparisonKpi)) + " ")])]), _c('v-card-text', {
    staticClass: "d-flex flex-column"
  }, [_c('v-row', {
    staticClass: "border-bottom"
  }, [_c('v-col', {
    staticClass: "border-right"
  }), _c('v-col', {}, [_vm._v(" " + _vm._s(_vm.functions.getTodaysDate()) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.comparison.budget.average')) + " ")]), _c('v-col', [_vm._v(" " + _vm._s(_vm.$t('planning.workforcePlanning.comparison.budget.budget')) + " ")])], 1), _c('v-row', {
    class: !_vm.state.isScenarioDisplayed && 'border-bottom'
  }, [_c('v-col', {
    staticClass: "border-right"
  }, [_vm._v(_vm._s(_vm.$t('planning.workforcePlanning.comparison.budget.position')))]), _c('v-col', [_vm._v(_vm._s((_vm$comparisonKpi = _vm.comparisonKpi) === null || _vm$comparisonKpi === void 0 ? void 0 : _vm$comparisonKpi.position.countToCurrentDate))]), _c('v-col', [_vm._v(_vm._s(_vm.functions.convertDotIntoCommaInput((_vm$comparisonKpi2 = _vm.comparisonKpi) === null || _vm$comparisonKpi2 === void 0 ? void 0 : _vm$comparisonKpi2.position.countAverage, 1)))]), _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.formatBudget((_vm$comparisonKpi3 = _vm.comparisonKpi) === null || _vm$comparisonKpi3 === void 0 ? void 0 : _vm$comparisonKpi3.position.sumBudget)) + " ")])], 1), !_vm.state.isScenarioDisplayed ? _c('v-row', [_c('v-col', {
    staticClass: "border-right"
  }, [_vm._v(_vm._s(_vm.$t('planning.workforcePlanning.comparison.budget.staffing')))]), _c('v-col', [_vm._v(_vm._s((_vm$comparisonKpi4 = _vm.comparisonKpi) === null || _vm$comparisonKpi4 === void 0 ? void 0 : _vm$comparisonKpi4.staffing.countToCurrentDate))]), _c('v-col', [_vm._v(_vm._s(_vm.functions.convertDotIntoCommaInput((_vm$comparisonKpi5 = _vm.comparisonKpi) === null || _vm$comparisonKpi5 === void 0 ? void 0 : _vm$comparisonKpi5.staffing.countAverage, 1)))]), _c('v-col', [_vm._v(" " + _vm._s(_vm.functions.formatBudget((_vm$comparisonKpi6 = _vm.comparisonKpi) === null || _vm$comparisonKpi6 === void 0 ? void 0 : _vm$comparisonKpi6.staffing.sumBudget)) + " ")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }