import { TranslateResult } from 'vue-i18n'

enum POSITION_PROPERTIES {
  ID = 'id',
  TITLE = 'title',
  START = 'start',
  END = 'end',
  SCOPE = 'scope',
  HOURLY_RATE = 'hourlyRate',
  DAILY_WORKING_TIME = 'dailyWorkingTime',
  SOURCING_TYPE = 'sourcingType',
  POSITION_STATUS = 'positionStatus',
  PROFILE = 'profile',
  ORGANIZATIONAL_UNIT = 'organizationalUnit',
  BUDGET_SOURCE_ORG_UNIT = 'budgetSourceOrgUnit',
  PROFESSIONAL_UNIT = 'professionalUnit',
  PLANNED_PERSON = 'plannedPerson',
  LIMITED = 'limited',
  DESCRIPTION = 'description',
  SCENARIO = 'scenario',
  SCENARIO_ORIGINAL_POSITION = 'scenarioOriginalPosition',
  ASSIGNMENTS = 'assignments',
  TENDERINGS = 'tenderings',
  APPROVALS = 'approvals',
  POSITION_COMMITS = 'positionCommits',
  TARGET_TRANSACTIONS = 'targetTransactions',
  SOURCE_TRANSACTION = 'sourceTransaction',
  AVAILABLE_SCOPE_FOR_TRANSACTION = 'availableScopeForTransaction',
  AVAILABLE_SCOPE_FOR_ASSIGNMENT = 'availableScopeForAssignment',
  GROUP = 'group',
  DAYS_TO_NEXT_POSITION = 'daysToNextPosition',
}

type TableHeader = {
  text: TranslateResult
  value: string
  sortable?: boolean
  width?: number | string
  class?: string
}

export { POSITION_PROPERTIES, TableHeader }
